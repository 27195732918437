<template>
  <!-- Error page-->
  <div class="register-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo/>
    </b-link>

    <div class="locale-nav">
      <locale/>
    </div>

    <b-container class="mt-5 mt-md-3">
      <b-row>
        <b-col md="8" offset-md="2">

          <div class="register-inner p-2 p-sm-3">
            <div class="w-100">
              <b-card :img-src="require('@/assets/images/register.jpeg')"
                      img-height="200" img-alt="card img" class="mb-3"
                      title="แบบฟอร์มการสมัครสมาชิกสำหรับผู้รับเหมา"
              >
                <b-card-text>
                  การขอสมัครสมาชิกเพื่อเข้ารับการอบรมก่อนเข้าทำงานภายใน บริษัท โตโต้ (ประเทศไทย) จำกัด, โดยหลังจากสมัครสมาชิกแล้วกรุณาติดต่อผู้ดูแลระบบของบริษัทท่าน
                  เพื่อขอรับการอนุมัติการเข้าใช้งานระบบ
                </b-card-text>
              </b-card>

              <b-overlay :show="isShowLoading">
                <validation-observer ref="validateForm" #default="{invalid}">
                  <b-form @submit.prevent="saveData">

                    <b-card :title="$t('user.personalInformation')">
                      <b-row>
                        <b-col md="6" v-if="!defaultCompanyId">
                          <b-form-group :label="$t('master.company.name') + '*'">
                            <validation-provider #default="{ errors }" :name="$t('master.company.name')" rules="required">
                              <v-select v-model="companyId" label="companyName"
                                        :options="optionCompanies" :reduce="option => option.companyId"
                                        :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6" v-if="!defaultCompanyId"></b-col>

                        <b-col md="6">
                          <b-form-group :label="$t('user.idCard') + '*'">
                            <validation-provider #default="{ errors }" :name="$t('user.idCard')" rules="required">
                              <b-form-input v-model="userIdCard" :state="errors.length > 0 ? false : null"/>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6">
                          <b-form-group :label="$t('user.prefix') + '*'">
                            <validation-provider #default="{ errors }" :name="$t('user.prefix')" rules="required">
                              <v-select v-model="prefixId" label="prefixName"
                                        :options="optionPrefixes" :reduce="option => option.prefixId"
                                        :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6">
                          <b-form-group :label="$t('user.firstName') + '*'">
                            <validation-provider #default="{ errors }" :name="$t('user.firstName')" rules="required">
                              <b-form-input v-model="userFirstName" :state="errors.length > 0 ? false : null"/>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6">
                          <b-form-group :label="$t('user.lastName') + '*'">
                            <validation-provider #default="{ errors }" :name="$t('user.lastName')" rules="required">
                              <b-form-input v-model="userLastName" :state="errors.length > 0 ? false : null"/>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6">
                          <b-form-group :label="$t('user.position') + '*'">
                            <validation-provider #default="{ errors }" :name="$t('user.position')" rules="required">
                              <v-select v-model="positionId" label="positionName"
                                        :options="optionPositions" :reduce="option => option.positionId"
                                        :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6">
                          <b-form-group :label="$t('user.tel') + '*'">
                            <validation-provider #default="{ errors }" :name="$t('user.tel')" rules="required|integer">
                              <b-form-input v-model="userTel" :state="errors.length > 0 ? false : null"/>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6" v-if="false">
                          <b-form-group :label="$t('user.bloodType')">
                            <v-select v-model="userBloodType" label="bloodName"
                                      :options="optionBloodTypes" :reduce="option => option.bloodId"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col md="6" v-if="false">
                          <b-form-group :label="$t('user.congenitalDisease')">
                            <b-form-input v-model="userCongenitalDisease"/>
                          </b-form-group>
                        </b-col>

                        <b-col md="6">
                          <b-form-group :label="$t('user.imageProfile') + '*'">
                            <thumbnail-file :src="userImagePath" v-if="userImagePath"/>
                            <validation-provider #default="{ errors }" :name="$t('user.imageProfile')" rules="required">
                              <b-form-file v-model="userImage" accept=".jpg, .png" :state="errors.length > 0 ? false : null"/>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6" align-self="end">
                          <b-form-group :label="$t('user.email') + '*'">
                            <validation-provider #default="{ errors }" :name="$t('user.email')" rules="required">
                              <b-form-input v-model="userEmail" :state="errors.length > 0 ? false : null"/>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6">
                          <b-form-group :label="$t('user.password') + '*'">
                            <validation-provider #default="{ errors }" name="password" rules="required|min:8|integer">
                              <b-form-input type="password" v-model="userPassword" :state="errors.length > 0 ? false : null"/>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6">
                          <b-form-group :label="$t('user.rePassword') + '*'">
                            <validation-provider #default="{ errors }" :name="$t('user.rePassword')" rules="required|confirmed:password">
                              <b-form-input type="password" v-model="userRePassword" :state="errors.length > 0 ? false : null"/>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>


                    </b-card>

                    <b-card :title="$t('user.emergencyContact')">
                      <b-row>
                        <b-col md="6">
                          <b-form-group :label="$t('user.emergencyName') + '*'">
                            <validation-provider #default="{ errors }" :name="$t('user.emergencyName')" rules="required">
                              <b-form-input v-model="userEmergencyName" :state="errors.length > 0 ? false : null"/>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6">
                          <b-form-group :label="$t('user.emergencyTel') + '*'">
                            <validation-provider #default="{ errors }" :name="$t('user.emergencyTel')" rules="required">
                              <b-form-input v-model="userEmergencyTel" :state="errors.length > 0 ? false : null"/>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>

                    <b-card :title="$t('user.attachments')">
                      <b-col cols="12" class="text-right">
                        <b-button variant="primary" @click="addRow" class="mb-2">{{ $t('user.btnAddAttachment') }}</b-button>
                      </b-col>

                      <b-row v-for="(row, key) in userAttachmentLists" :key="key" align-v="center" class="attachment-row">
                        <b-col md="6" order="2" order-md="1">
                          <b-form-group :label="$t('user.attachmentType') + '*'" :vid="'attachmentType' + key">
                            <validation-provider #default="{ errors }" :name="$t('user.attachmentType')" rules="required" :vid="'attachmentType' + key">
                              <v-select v-model="row.attachmentTypeId" label="attachmentTypeName"
                                        :options="optionAttachmentTypes" :reduce="option => option.attachmentTypeId"
                                        :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6" order="1" order-md="2" class="text-right">
                          <b-button variant="danger" size="sm" @click="deleteRow(key)">
                            {{ $t('general.btnDelete') }}
                          </b-button>
                        </b-col>

                        <b-col md="6" order="3">
                          <b-form-group :label="row.attachmentFilePath ? $t('user.attachmentFile') : $t('user.attachmentFile') + '*'">
                            <thumbnail-file :src="row.attachmentFilePath" v-if="row.attachmentFilePath"/>
                            <validation-provider #default="{ errors }" :name="$t('user.attachmentFile')" :rules="row.attachmentFilePath ? '' : 'required'" :vid="'attachmentFile' + key">
                              <b-form-file v-model="row.attachmentFile" accept=".jpg, .gif, .pdf, .png" :state="errors.length > 0 ? false : null"/>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="6" order="4" align-self="end">
                          <b-form-group :label="$t('user.trainingExpireDate') + '*'">
                            <div v-if="isPlainText">{{ row.attachmentExpiredDate }}</div>

                            <validation-provider #default="{ errors }" :name="$t('user.trainingExpireDate')" rules="required" :vid="'attachmentExpiredDate' + key" v-else>
                              <flat-pickr v-model="row.attachmentExpiredDate" class="form-control" :config="$store.state.main.datePickerConfig"/>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="12" order="4" align-self="end">
                          <b-form-group :label="$t('user.attachmentDesc')">
                            <b-form-input v-model="row.attachmentDescription"/>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>

                    <b-card :title="$t('user.termAndCondition')">
                      <validation-provider #default="{ errors }" :name="$t('user.termAndCondition')"
                                           :rules="`required|exact_length:${totalCompliance},${$t('user.validateTermAndCondition')}`">
                        <b-form-group
                            v-for="(row, key) in optionCompliances" :key="`com${key}`"
                            :label="switchLanguage(row.complianceTypeNameTh, row.complianceTypeNameEn)"
                        >
                          <div v-for="option in row.complianceLists" :key="option.complianceId">

                            <b-form-checkbox
                                v-model="userTermAndCondition"
                                :value="option.complianceId"
                            >
                              {{ switchLanguage(option.complianceNameTh, option.complianceNameEn) }}
                            </b-form-checkbox>
                          </div>
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>

                      </validation-provider>

                    </b-card>

                    <b-row>
                      <b-col class="text-center">
                        <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                          <b-spinner small v-if="isBtnDisabled"/>
                          <feather-icon icon="SaveIcon" v-else/>
                          {{ $t('general.btnSubmit') }}
                        </b-button>
                      </b-col>
                    </b-row>

                  </b-form>
                </validation-observer>

              </b-overlay>
            </div>
          </div>

        </b-col>
      </b-row>
    </b-container>


  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {BLink, BButton, BImg, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BFormCheckbox, BFormFile, BOverlay, BContainer} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import {required, min, confirmed, integer, exact_length} from '@validations'
import tableMixins from "@/mixins/tableMixins"
import masterMixins from "@/mixins/masterMixins"
import {RegisterService, UserAttachmentService, FileStorageService} from "@/services"
import Locale from "@core/layouts/components/app-navbar/components/Locale"

const registerService = new RegisterService()
const userAttachmentService = new UserAttachmentService()
const fileStorageService = new FileStorageService()

export default {
  components: {
    Locale,
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormFile,
    BSpinner,
    BOverlay,
    BContainer,
  },
  mixins: [tableMixins, masterMixins],
  async created() {
    this.defaultCompanyId = this.$route.params.companyId
    this.companyId = parseInt(this.defaultCompanyId)

    let [listCompany, listPrefixes, listPositions, listAttachmentType, listCompliance] = await Promise.all([
      this.listCompany(),
      this.listPrefix(),
      this.listPosition(),
      this.listAttachmentType(),
      this.listCompliance(),
    ])

    this.optionCompanies = listCompany
    this.optionPrefixes = listPrefixes
    this.optionPositions = listPositions
    this.optionAttachmentTypes = listAttachmentType
    this.optionCompliances = listCompliance

    this.isShowLoading = false
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    defaultCompanyId: '',

    userTermAndCondition: [],
    userImagePath: '',
    companyId: '',
    prefixId: '',
    userFirstName: '',
    userLastName: '',
    userIdCard: '',
    positionId: '',
    // userBloodType: '',
    userCongenitalDisease: '',
    userTel: '',
    userEmail: '',
    userPassword: '',
    userRePassword: '',
    userGroupId: '',
    userCategory: '',
    userEmergencyName: '',
    userEmergencyTel: '',

    /*prefixId: 3,
    userFirstName: 'สมศรี',
    userLastName: 'มีชัย',
    userIdCard: '11037005700832',
    positionId: 1,
    userBloodType: 3,
    userCongenitalDisease: '-',
    userTel: '0818188216',
    userEmail: 'test01@gmail.com',
    userPassword: '!QAZ2wsx3edc',
    userRePassword: '!QAZ2wsx3edc',
    userEmergencyName: 'aa',
    userEmergencyTel: 'bb',*/

    userImage: [],

    userAttachmentLists: [],
    optionCompanies: [],
    optionPrefixes: [],
    optionUserGroups: [],
    optionPositions: [],
    // optionBloodTypes: [],
    optionAttachmentTypes: [],
    optionCompliances: [],
    option: [
      {
        prefixId: 1,
        prefixName: 'Mr.'
      }, {
        prefixId: 2,
        prefixName: 'Ms.'
      }, {
        prefixId: 3,
        prefixName: 'Miss.'
      }]
  }),
  computed: {
    totalCompliance() {
      let total = 0
      this.optionCompliances.map(comType => {
        total += comType.complianceLists.length
      })

      return total
    }
  },
  methods: {
    saveData() {
      this.$refs.validateForm.validate()

      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true
          let uploadResult = {fileId: null}
          if (Object.entries(this.userImage).length > 0) {
            uploadResult = await fileStorageService.upload(this.userImage)
            // {isSuccess: true, message: 'Success', fileId: 8}
            if (!uploadResult.isSuccess) {
              this.$store.commit('main/setToastError', {message: uploadResult.message})
              this.isBtnDisabled = false
              return false
            }
          }

          let actionData = {
            fileId: uploadResult.fileId,
            companyId: this.companyId,
            prefixId: this.prefixId,
            userFirstName: this.userFirstName,
            userLastName: this.userLastName,
            userIdCard: this.userIdCard,
            positionId: this.positionId,
            // userBloodType: this.userBloodType,
            // userCongenitalDisease: this.userCongenitalDisease,
            userTel: this.userTel,
            userEmail: this.userEmail,
            userGroupId: this.userGroupId,
            userEmergencyName: this.userEmergencyName,
            userEmergencyTel: this.userEmergencyTel,
          }

          let saveResult
          actionData.userPassword = this.userPassword
          actionData.userRePassword = this.userRePassword
          saveResult = await registerService.createData(actionData)

          if (saveResult.isSuccess) {
            await this.saveAttachment(saveResult.data.result.userId)
          }

          this.isBtnDisabled = false

          if (saveResult.isSuccess) {
            this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
            await this.$router.replace({name: 'publicRegisterSuccess'})
          } else {
            this.$store.commit('main/setToastError', saveResult.data)
          }
        }
      })
    },
    addRow() {
      this.userAttachmentLists.push({
        attachmentId: '',
        attachmentDescription: '',
        attachmentExpiredDate: '',
        attachmentTypeId: null,
        attachmentFile: [],
        attachmentFilePath: '',
        attachmentFileRealName: '',
        attachmentNum: this.userAttachmentLists.length + 1,
      })
    },
    deleteRow(row) {
      this.userAttachmentLists.splice(row, 1)
      this.userAttachmentLists = this.userAttachmentLists.map((data, key) => ({...data, attachmentNum: key + 1}))
    },
    async saveAttachment(userId) {
      let attachmentLists = []
      await Promise.all(
          this.userAttachmentLists.map(async data => {
            let row = {
              attachmentId: data.attachmentId,
              attachmentDescription: data.attachmentDescription,
              attachmentExpiredDate: data.attachmentExpiredDate,
              attachmentTypeId: data.attachmentTypeId,
              attachmentNum: data.attachmentNum,
            }
            if (Object.entries(data.attachmentFile).length > 0) {
              let uploadResult = await fileStorageService.upload(data.attachmentFile)
              row.attachmentFileId = uploadResult.fileId
            }
            attachmentLists.push(row)
          })
      )

      let actionData = {
        userAttachmentLists: attachmentLists,
        userId: userId
      }

      await userAttachmentService.saveData(actionData)

    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.register-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;

  .register-inner {
    position: relative;
    max-width: 900px;
  }

  .brand-logo {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 2rem;
    left: 2rem;
    margin: 0;

    .brand-text {
      font-weight: 600;
    }
  }

  .locale-nav {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 2rem;
    margin: 0;

    ::marker {
      color: #fff;
    }
  }
}
</style>
